<template>
    <div
    id="assignFighterPopup"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="assignFighterPopup"
    aria-hidden="true"
    @click.self="closeModal">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header" style="height: 60px; width: 100%; display: flex; align-items: center; justify-content: space-between;">
                    <h5 style="flex: 1; margin: 0;"> Assign Fighters ({{ localPackData?.fighters?.length?? 0 }}/16)</h5>
                    <div class="d-flex" style="gap: 15px;">
                        <button class="btn btn-primary" ref="Close" type="button" data-bs-dismiss="modal" aria-label="Close" @click="updateFighter">Import</button>
                        <button class="close-button" ref="Close" type="button" data-bs-dismiss="modal" aria-label="Close" @click="closeModal">X</button>
                    </div>
                </div>
                <div class="modal-body card">
                    <div class="card-body">
                        <div v-if="localTotalFighters && localTotalFighters.length > 0">
                            <div class="product-list">
                                <AssignFighterItem v-for="(option, index) in localTotalFighters" :key="index" :fighter="option" :selectedCount="localPackData?.fighters?.length?? 0" :selectingFighterId="selectingFighter?.id?? 0" @setSelectedFighter="setSelectedFighter"/>
                            </div>
                        </div>
                        <div v-else>
                            <img src="~@/assets/no-data.png" alt="no data"
                                style="display: block; margin-left: auto; margin-right: auto; width: 160px;">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { useStore } from '@/stores/index.ts';
import axios from 'axios';
import { watch } from 'vue';
import { useToast } from 'vue-toast-notification';
import { getClassImageUrl, getElementImageUrl } from '@/utils/imageUtils.js';
import AssignFighterItem from '@/components/AssignFighterItem.vue';
import ConfirmPopup from '@/components/popup/ConfirmPopup.vue'

export default {
    components: {
        AssignFighterItem,
        ConfirmPopup
    },
    props: {
        showModal: Boolean,
        packData: {
            type: Object,
            required: true
        },
        totalFighters: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            selectedFighters: [],
            selectingFighter: null,
            localPackData: {...this.packData},
            localTotalFighters: []
        };
    },
    watch: {
        packData: {
            handler(newVal) {
                // console.log('Message prop updated:', newVal.name);
                if(!newVal){
                    return ;
                }
                this.localPackData = {...newVal};

                this.resetFighterList();
               
                // console.log('Message prop updated:', this.localPackData);
                // console.log('Message prop updated:', this.selectedFighters);
            },
            deep: true
        },
        totalFighters: {
            handler(newVal) {
                // console.log('Message prop updated:', newVal);
                if(!newVal){
                    return ;
                }

                this.localTotalFighters = [...newVal];
                this.localTotalFighters = this.localTotalFighters.filter(f=>!f.isSelected);
                // this.resetFighterList();
                // console.log('Message prop updated:', this.localTotalFighters);
            },
            deep: true
        }
    },
    mounted() {
        console.log('totalFighters', this.totalFighters);
    //   try {
    //       if(useStore().getWalletAddress!=''){
    //           this.fetchFighter();
    //       }else{
    //           watch(() => useStore().getWalletAddress, () => {
    //               this.fetchFighter();
    //           });
    //       }
    //   } catch (error){ 
    //       console.error('Error:', error);
    //   }
    },
    methods: {
        // async fetchFighterData(fighters) {
        //     try {
        //         for (const fighter of fighters){
        //             const response = await axios.get(`https://s3.ap-southeast-1.amazonaws.com/metadata.kryptofighters.io/fighter/${fighter.tokenId}.json`);
        //             console.log(response);
        //             var imageUrl = response.data.image;
        //             imageUrl = imageUrl.replace("image/4x/", "card/no-bg/");
        //             const _tempData = {
        //                 id: fighter.tokenId,
        //                 image: imageUrl,
        //                 class_id: response.data.properties.class_id,
        //                 element_id: response.data.properties.element,
        //                 classImgUrl: getClassImageUrl(response.data.properties.class_id),
        //                 elementUrl: getElementImageUrl(response.data.properties.element),
        //                 isSelected: false
        //             }

        //             this.localTotalFighters.push(_tempData);
        //         }
        //         console.log('this.totalFighters', this.localTotalFighters);
        //             // await this.fetchPack();
        //     } catch (error) {
        //         console.error('Error fetching image:', error);
        //     }
        // },
        // async fetchFighter(){
        //     try {
        //         const accountAddress = useStore().getWalletAddress;
        //         const collection = process.env.VITE_FIGHTER_CONTRACT_ADDRESS;
        //         const apiUrl = `https://api.rarible.org/v0.1/ownerships/search`;
        //         const requestBody = {
        //             size: 50,
        //             filter: {
        //                 blockchains: ['KROMA'],
        //                 owners: [`ETHEREUM:${accountAddress}`],
        //                 collections: [`KROMA:${collection}`]
        //             }
        //         };
        //         const response = await axios.post(apiUrl, requestBody, {
        //             headers: {
        //                 'accept': 'application/json',
        //                 'x-api-key': process.env.VITE_RARIBLE_API_KEY
        //             }
        //         });
        //         console.log("gen1Response ",response);

        //         this.fetchFighterData(response.data.ownerships);
        //     } catch (error) {
        //         console.error('Error fetching data from OpenSea:', error);
        //     }
        // },
        // getTotalFighters(){
        //     // this.localTotalFighters.forEach(element => {
        //     //     element.isSelected = this.selectedFighters.some(s=>s.id === element.id);
        //     // });
        //     return this.localTotalFighters;
        // },
        closeModal() {
            console.log('Closing modal'); // Add this line
            // this.$refs.Close.click();

            // this.$emit('assignFighter', this.selectedFighters);
            // this.localTotalFighters.forEach(element => {
            //     element.isSelected = false;
            // });

            this.resetFighterList();
            console.log('setSelectedFighter2', this.localTotalFighters);
        },
        updateFighter(){
            this.selectedFighters.push(this.selectingFighter);
            console.log('this.selectedFighters', this.selectedFighters);
            this.$emit('assignFighter', this.selectedFighters);
            this.selectedFighters.forEach(element => {
                this.localTotalFighters.find(f=>f.id === element.id).isSelected = true;
            });
            this.resetFighterList();
            console.log('setSelectedFighter2', this.localTotalFighters);
        },
        resetFighterList(){
            this.selectedFighters = [];
            this.selectingFighter = null;
            this.localTotalFighters.forEach(element => {
                element.isSelecting = false;
            });
            this.localTotalFighters = this.localTotalFighters.filter(f=>!f.isSelected);
        },
        setSelectedFighter(fighter) {
            console.log("setMasterToken "+fighter.id);
            if(this.selectingFighter === fighter.id){
                this.selectingFighter = null;
            }
            else{
                this.selectingFighter = fighter;
            }
            // if (this.selectedFighters.some(s=>s.id === fighter.id)) {
            //     this.selectedFighters = this.selectedFighters.filter(f => f.id !== fighter.id);
            // } else {
            //     this.selectedFighters.push(fighter);
            // }
            console.log('setSelectedFighter', this.selectingFighter);
            // console.log('setSelectedFighter2', this.localTotalFighters);
        }
    }
};
</script>

<style scoped lang="scss">

.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    /* Adjust minmax values as per your design */
    gap: 20px;
    /* Adjust gap between grid items */
}

.modal-dialog {
    max-width: 1000px;
    max-height: auto;
}

.modal-body {
  max-height: 100%; /* Adjust this value as needed */
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
}

.close-button {
//   position: absolute;
//   top: 10px;
//   right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.modal-content {
    border: 0px;
    background-color: var(--primary-background-color);
}

@media (max-width: 768px) {
    .modal-title {
        font-size: 120%;
    }
    
    .modal-body {
        padding: 0.5rem 0;
    }
    .term-detail {
        padding: 0.2rem 0.5rem;
        gap: 5px;
    }

    .header-row {
        margin-bottom: 0.2rem;
    }

    .modal-header {
        padding: 0.5rem 1rem;
    }

    .detail-block {
        display: flex;
        flex-direction: column;
        overflow: scroll;
        width: auto;
        height: auto;
        max-height: 328px;
    }
}
</style>
  
