<template>
  <div
    id="matchHistoryPopup"
    class="modal fade no-padding"
    tabindex="-1"
    aria-labelledby="matchHistoryPopup"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" style="--bs-modal-width: 700px;">
      <div class="modal-content" style="height: 500px; overflow-y: scroll;">
        <div class="modal-header">
          <h5 id="exampleModalLabel" class="modal-title">{{ scholar?.name?? '' }} History</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <!-- <div v-if="isLoading" class="loading-content" style="/*padding: 50px 0px;*/" >
          <div class="loading-bar"></div>
        </div> -->
        <div class="modal-body">
          <table class="" style="width: 100%;">
            <colgroup>
              <col style="width: 45%;">
              <col style="width: 35%;">
              <col style="width: 20%;">
            </colgroup>
            <thead class="">
              <tr>
                <th class="table-cell" scope="col">
                  <div class="table-head">
                    PLAYER
                  </div>
                </th>
                <th class="table-cell" scope="col">
                  <div class="table-head">
                    TIMESTAMP
                  </div>
                </th>
                <th class="table-cell" scope="col">
                  <div class="table-head">
                    RESULT
                  </div>
                </th>
              </tr>
            </thead>
            <tbody v-if="isLoading">
              <tr class="table-row">
                <td class="table-cell-list">
                  <v-skeleton-loader
                    :type="'list-item-two-line'"
                    :animation="true"
                    :width="'auto'"
                    :height="'auto'"
                    theme="dark"
                  />
                </td>
                <td class="table-cell-list">
                  <v-skeleton-loader
                    :type="'list-item-two-line'"
                    :animation="true"
                    :width="'auto'"
                    :height="'auto'"
                    theme="dark"
                  />
                </td>
                <td class="table-cell-list">
                  <v-skeleton-loader
                    :type="'avatar, heading'"
                    :animation="true"
                    :width="'auto'"
                    :height="'auto'"
                    theme="dark"
                  />
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <template v-if="matchData.length > 0">
                <tr v-for="match in matchData" :key="match.id" class="table-row">
                  <td class="table-cell-list">
                    <div class="cell-upper-text">
                      {{ getWalletName(match.user_address) }}
                    </div>
                    <div class="cell-lower-text">
                      {{ getShortenedWallet(match.user_address) }}
                    </div>
                  </td>
                  <td class="table-cell-list">
                    <div class="cell-upper-text">
                      {{ getDate(match.end_timestamp) }}
                    </div>
                    <div class="cell-lower-text">
                      {{ getTime(match.end_timestamp) }}
                    </div>
                  </td>
                  <td class="table-cell-list">
                    <div class="d-flex">
                      <img
                        :src="require(`@/assets/icons/battleResult/${getBattleResult(match.match_result)}.png`)"
                        height="30"
                        style="margin-right: 5px;"
                      />
                      <div class="cell-upper-text">
                        <div v-html="getBattleResult(match.match_result, true)"></div>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="3" style="text-align: center;">
                    <img
                      src="~@/assets/no-data.png"
                      alt="no data"
                      style="display: block; margin-left: auto; margin-right: auto; width: 160px;"
                    />
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import {shortenedWalletAddress} from '@/utils/walletUtils.js';
import { convertDateString, convertTimeString } from '@/utils/timeUtils.js';

export default {
    components: {
    },
    props: {
      showModal: Boolean,
      scholar: {
          type: Object,
          required: true,
          default: () => {}
      },
    },
    data() {
        return {
          isLoading: false,
          matchData: []
        };
    },
    watch: {
      scholar: {
          handler(newVal) {
              // console.log('Message prop updated:', newVal);

              this.fetchMatchLog(newVal.id);
          },
          deep: true
        },
    },
    mounted() {
    },
    methods: {
      closeModal(){
        console.log('closeModal');
        // this.$refs.Close.click();
      },
      async fetchMatchLog(scholarId){
        if(scholarId === null || scholarId === undefined){
          return;
        }
        console.log('scholarId',scholarId);
        this.isLoading = true;
        try{
          const apiUrl = `https://reward-portal.kryptofighters.io/api/scholar/game_logs?scholar_id=${scholarId}`;
          const response = await axios.get(apiUrl);
          console.log('response',response);
          this.matchData = response.data.data;
          this.matchData.sort((a, b) => {
            return a.end_timestamp - b.end_timestamp;
          });
          this.isLoading = false;
        }
        catch(error){
          console.error('Error fetching data :', error);
          this.isLoading = false;
        }
      },
      getShortenedWallet(wallet){
        return shortenedWalletAddress(wallet);
      },
      getWalletName(wallet){
        return (wallet)? "Fighterian #"+wallet.substr(0, 4):"Fighterian #000";
      },
      getBattleResult(result, isText=false){
        var text = "";
        if(isText){
          switch(result){
            case 0: text = "<span style='color: #FF4141;'>"; break;
            case 2: text = "<span style='color: #00C2FF;'>"; break;
          }
        }

        var text2 = "";
        switch(result){
          case 0: text2 = "lost"; break;
          case 2: text2 = "victory"; break;
        }

        if(isText){
          text += text2.toUpperCase();
          text += "</span>";
        }
        else{
          text = text2;
        }

        return text;
      },
      getDate(timestamp){
        const date = new Date(timestamp);
        const unixTimestamp = Math.floor(date.getTime() / 1000);
        return convertDateString(unixTimestamp);
      },
      getTime(timestamp){
        const date = new Date(timestamp);
        const unixTimestamp = Math.floor(date.getTime() / 1000);
        return convertTimeString(unixTimestamp);
      }
    }
};
</script>

<style scoped lang="scss">
  .no-padding {
    padding: 0 !important;
  }

  .table-cell {
    padding: 20px 16px;
    padding-top: 0;
  }

  .table-cell-list {
    padding: 20px 16px;
  }

  .table-head {
    font-size: x-small;
    color: #8e97a8;
  }

  .table-row:nth-child(odd) {
    background-color: var(--secondary-background-color);
  }

  .cell-upper-text {
    /* border: 1px solid pink; */
    color: white;
    font-size: 14p;
    font-weight: 600;
  }

  .cell-lower-text {
      /* border: 1px solid pink; */
      color: #5b6372;
      font-size: small;
  }

  .loading-content {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    position: relative;
    z-index: 1;
    /* top: -650px; */
    background-color: #100f17;
  }

  .loading-bar {
    width: 100%;
    height: 2px;
    background-color: var(--primary-background-color);
    position: relative;
    overflow: hidden;
    border-radius: 0px; 
  }

  .loading-bar:before {
    content: "";   
    position: absolute;
    top: 0;
    left: -100%;             
    width: 100%;            
    height: 100%;           
    background-image: linear-gradient(100deg, #df4bd3 50%, #8322ce 100%);
    animation: loading 2s linear infinite;
  }

  @keyframes loading {
    0% {
      left: -100%;            /* Start off-screen to the left */
    }
    100% {
      left: 100%;             /* Move fully to the right */
    }
  }
  @media (max-width: 768px) {
  }
</style>
  
