import { defineStore } from 'pinia';

interface MapSkill {
    class_id: number,
    part_id: number;
    part_name: string;
    skill_code: string;
    skill_name: string;
    min_damage: number;
    max_damage: number;
    cri: number;
    acc: number;
    pos_use: string;
    pos_target: string;
    description: string;
}

export const useSkillStore = defineStore({
    id: 'mapSkills',
    state: () => ({
        mapSkills: [] as MapSkill[],
        defaultSkills: new Map<number, MapSkill[]>()
    }),
    actions: {
        async fetchMapSkillData(this: any) { // Adjust the type of 'this' as per Pinia's requirement
            if (this.mapSkills.length !== 0) {
                return;
            }

            try {
                const response = await fetch('/csv/normalMove-data.csv');
                
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                
                const csvData = await response.text();

                const rows = csvData.split('\n').slice(1);

                rows.forEach(row => {
                    const values = row.replace(/#N\/A/g, "").split(',');
                    if(!isNaN(Number(values[0])) && values[2] != ''){
                        var _desc = 'DMG : '+parseInt(values[4].trim())+'-'+parseInt(values[5].trim())+
                                    ' CRIT : '+parseInt(values[6].trim())+
                                    ' ACC : '+parseInt(values[7].trim())+
                                    '<br>'+values[10].trim();

                        const skill: MapSkill = {
                            class_id: 0,
                            part_id: parseInt(values[0].trim()),
                            part_name: values[1].trim(),
                            skill_code: values[2].trim(),
                            skill_name: values[3].trim(),
                            min_damage: parseInt(values[4].trim()),
                            max_damage: parseInt(values[5].trim()),
                            cri: parseInt(values[6].trim()),
                            acc: parseInt(values[7].trim()),
                            pos_use: values[8].trim(),
                            pos_target: values[9].trim(),
                            description: _desc
                        };

                        this.mapSkills.push(skill);
                    }
                    
                });

                try {
                    await this.fetchDefaultSkillData();
                } catch (error) {
                    console.error('Error:', error);
                }
                console.log('Total skills:', this.mapSkills.length);
            } catch (error) {
                console.error('Error:', error);
            }
        },
        async fetchDefaultSkillData(this: any) { // Adjust the type of 'this' as per Pinia's requirement
            if (this.defaultSkills.size !== 0) {
                return;
            }

            try {
                const response = await fetch('/csv/defaultMove-data.csv');
                
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                
                const csvData = await response.text();

                const rows = csvData.split('\n').slice(1);

                rows.forEach((row, index) => {
                    const values = row.replace(/#N\/A/g, "").split(',');
                    // console.log('Total skills:', row);
                    if(values[1] != ''){
                        var _desc = values[9].trim();
                        if(_desc == ''){
                            _desc = 'DMG : '+parseInt(values[3].trim())+'-'+parseInt(values[4].trim())+
                                    ' CRIT : '+parseInt(values[5].trim())+
                                    ' ACC : '+parseInt(values[6].trim());
                        }

                        const skill: MapSkill = {
                            class_id: parseInt(values[0].trim()),
                            part_id: 0,
                            part_name: '',
                            skill_code: values[1].trim(),
                            skill_name: values[2].trim(),
                            min_damage: parseInt(values[3].trim()),
                            max_damage: parseInt(values[4].trim()),
                            cri: parseInt(values[5].trim()),
                            acc: parseInt(values[6].trim()),
                            pos_use: values[7].trim(),
                            pos_target: values[8].trim(),
                            description: _desc
                        };
                        // console.log('Total skills:', this.defaultSkills);

                        if(skill.class_id !== 0) {
                            if(this.defaultSkills.has(skill.class_id)) {
                                const existingSkills = this.defaultSkills.get(skill.class_id) || [];
                                existingSkills.push(skill);
                                this.defaultSkills.set(skill.class_id, existingSkills);
                            }else {
                                // Otherwise, set a new entry in defaultSkills
                                this.defaultSkills.set(skill.class_id, [skill]);
                            }
                        }
                    }
                });
                console.log('Total skills:', this.defaultSkills.size);
            } catch (error) {
                console.error('Error:', error);
            }
        },
    },
    getters: {
        getMapSkillData: (state) => (partID: number) => {
            console.log("Get partID:", partID);
            if (state.mapSkills.length < 1) {
                return null;
            }
            else{
                return state.mapSkills.find(part => part.part_id === partID);
            }
        },
        getDefaultSkillData: (state) => (classId: number) => {
            console.log("Get classId:", classId);
            if (state.defaultSkills.size < 1) {
                return null;
            }
            else{
                return (state.defaultSkills.has(classId)) ? state.defaultSkills.get(classId) : null;
            }
        },
        getReplaceSkillDescription: (state) => (description: string): string => {
            let effectMaps: { [key: string]: string } = {
                '[Crit]': " <img src='../images/effect/Crit.png' style='width: auto; height: 24px;' alt=''> Crit ",
                '[Counter]': " <img src='../images/effect/Counter.png' style='width: auto; height: 24px;' alt=''> Counter ",
                '[DodgeUp]': " <img src='../images/effect/DodgeUp.png' style='width: auto; height: 24px;' alt=''> DodgeUp ",
                '[DodgeUpEx]': " <img src='../images/effect/DodgeUpEx.png' style='width: auto; height: 24px;' alt=''> DodgeUpEx ",
                '[Shield]': " <img src='../images/effect/Shield.png' style='width: auto; height: 24px;' alt=''> Shield ",
                '[ShieldEx]': " <img src='../images/effect/ShieldEx.png' style='width: auto; height: 24px;' alt=> ShieldEx ",
                '[Guarded]': " <img src='../images/effect/Guarded.png' style='width: auto; height: 24px;' alt=> Guarded ",
                '[ResUp]': " <img src='../images/effect/ResUp.png' style='width: auto; height: 24px;' alt=> ResUp ",
                '[AttackUp]': " <img src='../images/effect/AttackUp.png' style='width: auto; height: 24px;' alt=> AttackUp ",
                '[AttackUpEx]': " <img src='../images/effect/AttackUpEx.png' style='width: auto; height: 24px;' alt=> AttackUpEx ",
                '[SpeedUp]': " <img src='../images/effect/SpeedUp.png' style='width: auto; height: 24px;' alt=> SpeedUp ",
                '[Cure]': " <img src='../images/effect/Cure.png' style='width: auto; height: 24px;' alt=> Cure ",
                '[Dazed]': " <img src='../images/effect/Dazed.png' style='width: auto; height: 24px;' alt=> Dazed ",
                '[Stunned]': " <img src='../images/effect/Stunned.png' style='width: auto; height: 24px;' alt=> Stunned ",
                '[Blinded]': " <img src='../images/effect/Blinded.png' style='width: auto; height: 24px;' alt=> Blinded ",
                '[AttackDown]': " <img src='../images/effect/AttackDown.png' style='width: auto; height: 24px;' alt=> AttackDown ",
                '[Weakened]': " <img src='../images/effect/Weakened.png' style='width: auto; height: 24px;' alt=> Weakened ",
                '[Provoked]': " <img src='../images/effect/Provoked.png' style='width: auto; height: 24px;' alt=> Provoked ",
                '[Poisoned]': " <img src='../images/effect/Poisoned.png' style='width: auto; height: 24px;' alt=> Poisoned ",
                '[Locked]': " <img src='../images/effect/Locked.png' style='width: auto; height: 24px;' alt=> Locked ",
                '[HoldFast]': " <img src='../images/effect/Holdfast.png' style='width: auto; height: 24px;' alt=> HoldFast ",
                '[Bomb]': " <img src='../images/effect/Bomb.png' style='width: auto; height: 24px;' alt=> Bomb ",
                '[Relentless]': " <img src='../images/effect/Relentless.png' style='width: auto; height: 24px;' alt=> Relentless ",
                '[Barrier]': " <img src='../images/effect/Barrier.png' style='width: auto; height: 24px;' alt=> Barrier ",
                '[Drain]': " <img src='../images/effect/Drain.png' style='width: auto; height: 24px;' alt=> Drain ",
                '[Marked]': " <img src='../images/effect/Marked.png' style='width: auto; height: 24px;' alt=> Marked ",
                '[Rage]': " <img src='../images/effect/Rage.png' style='width: auto; height: 24px;' alt=> Rage ",
                '[PassiveBuff]': " <img src='../images/effect/PassiveBuff.png' style='width: auto; height: 24px;' alt=> PassiveBuff ",
                '[PassiveDebuff]': " <img src='../images/effect/PassiveBuff.png' style='width: auto; height: 24px;' alt=> PassiveDebuff ",
                '[LastStand]': " <img src='../images/effect/LastStand.png' style='width: auto; height: 24px;' alt=> LastStand ",
                '[BurnOut]': " <img src='../images/effect/BurnOut.png' style='width: auto; height: 24px;' alt=> BurnOut ",
                '[Panic]': " <img src='../images/effect/Panic.png' style='width: auto; height: 24px;' alt=> Panic ",
                '[Heal]': " <img src='../images/effect/Heal.png' style='width: auto; height: 24px;' alt=> Heal ",
                '[FightingSpiritUp]': " <img src='../images/effect/IncreaseFS.png' style='width: auto; height: 24px;' alt=> FightingSpiritUp ",
                '[FightingSpiritDown]': " <img src='../images/effect/DecreaseFS.png' style='width: auto; height: 24px;' alt=> FightingSpiritDown ",
                '[MoveForward]': " <img src='../images/effect/MoveForward.png' style='width: auto; height: 24px;' alt=> MoveForward",
                '[MoveBack]': " <img src='../images/effect/MoveBackward.png' style='width: auto; height: 24px;' alt=> MoveBack",
                '[KnockBack]': " <img src='../images/effect/MoveBack.png' style='width: auto; height: 24px;' alt=> KnockBack",
                '[PullForward]': " <img src='../images/effect/PullForward.png' style='width: auto; height: 24px;' alt=> PullForward",
                '[Swap]': " <img src='../images/effect/Swap.png' style='width: auto; height: 24px;' alt=> Swap ",
                '[SetCombo]': "<img src='../images/effect/SetCombo.png' style='width: auto; height: 24px;' alt=>SetCombo ",
                '[ExecuteCombo]': " <img src='../images/effect/ExecuteCombo.png' style='width: auto; height: 24px;' alt=> ExecuteCombo ",
                '[Purge]': " <img src='../images/effect/Purge.png' style='width: auto; height: 24px;' alt=> Purge ",
                '[Bled]': " <img src='../images/effect/Bled.png' style='width: auto; height: 24px;' alt=> Bleed ",
                '[Disarm]': " <img src='../images/effect/Disarm.png' style='width: auto; height: 24px;' alt=> Disarm ",
                '[Immortal]': " <img src='../images/effect/Immortal.png' style='width: auto; height: 24px;' alt=> Immortal ",
                '[ExecuteWeakCombo]': " <img src='../images/effect/ExecuteWeakCombo.png' style='width: auto; height: 24px;' alt=> Execute Weak Combe ",
                '[ExecuteLockCombo]': " <img src='../images/effect/ExecuteLockCombo.png' style='width: auto; height: 24px;' alt=> Execute Lock Combe ",
                '[ExecuteStunCombo]': " <img src='../images/effect/ExecuteStunCombo.png' style='width: auto; height: 24px;' alt=> Execute Stun Combe ",
                '[AccUp]': " <img src='../images/effect/AccUp.png' style='width: auto; height: 24px;' alt=> Accuracy Up ",
                '[AccDown]': " <img src='../images/effect/AccDown.png' style='width: auto; height: 24px;' alt=> Accuracy Down ",
                '[DodgeDown]': " <img src='../images/effect/DodgeDown.png' style='width: auto; height: 24px;' alt=> Dodge Down ",
                '[Stealth]': " <img src='../images/effect/Stealth.png' style='width: auto; height: 24px;' alt=> Stealth ",
                '[FrontLineDefense]': " <img src='../images/effect/FrontLineDefense.png' style='width: auto; height: 24px;' alt=> FrontLineDefense ",
                '[BloodRite]': " <img src='../images/effect/BloodRite.png' style='width: auto; height: 24px;' alt=> BloodRite ",
            };
      
            let descp = description;
            for (let key in effectMaps) {
              if (effectMaps.hasOwnProperty(key)) {
                let value = effectMaps[key];
                descp = descp.replace(key, value);
              }
            }
            return descp;
            
          },
    },   
});

export function getClassPosition(classId) {
    var position = "";
    switch(classId)
    {
        case 7: position = "xxoo"; break;
        case 2: position = "xxoo"; break;
        case 1: position = "xoox"; break;
        case 4: position = "ooxx"; break;
        case 8: position = "xxoo"; break;
        case 6: position = "ooxx"; break;
        case 3: position = "xoox"; break;
        case 5: position = "xoox"; break;
        default: position = "xxxx"; break;
    }

    return position;
}