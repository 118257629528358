<template>
  <div
    id="confirmPopup"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="confirmPopup"
    aria-hidden="true">
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="exampleModalLabel" class="modal-title">{{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          {{ message }}
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            {{ cancelButtonText }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="confirmAction"
          >
            {{ confirmButtonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

  
  <script>
  import { defineProps, defineEmits } from 'vue';
  
  export default {
    props: {
      // isVisible: {
      //   type: Boolean,
      //   default: false
      // },
      title: {
        type: String,
        required: true
      },
      message: {
        type: String,
        required: true
      },
      confirmButtonText: {
        type: String,
        default: 'OK'
      },
      cancelButtonText: {
        type: String,
        default: 'Cancel'
      }
    },
    data() {
        return {
          // showModal: false
        };
    },
    // emits: ['confirm', 'cancel'],
    methods: {
      closeModal() {
        // this.$emit('update:isVisible', false); // Close the modal
        console.log('closeModal');
      },
      confirmAction() {
        this.$emit("confirm"); // Trigger the confirm event
        this.closeModal(); // Close the modal
      },
    },
    watch: {
      // isVisible: {
      //     handler(newVal) {
      //       console.log('Message prop updated:', newVal);
      //       this.showModal = newVal;
      //     },
      //     deep: true
      //   }
      // isVisible(newVal) {
      //   const modalElement = this.$refs.modal;
      //   const modalInstance = bootstrap.Modal.getOrCreateInstance(modalElement);

      //   if (newVal) {
      //     modalInstance.show(); // Show the modal
      //   } else {
      //     modalInstance.hide(); // Hide the modal
      //   }
      // },
    },
  };
  </script>
  
  <style scoped>
  .modal-overlay {
  display: none;
  /* other styles */
}

.modal.fade.show .modal-overlay {
  display: block;
}

  </style>
  