<template>
  <div class="page-content content-wrap"  :style="{ display: isLoading ? 'none' : 'block' }">
    <div class="main-content">
      <div class="d-flex align-items-center justify-content-between default-top-space head-title">
        <div>
          <h1>Scholarship Program</h1>
          <!-- <p>Description</p> -->
        </div>
      </div>
      <div class="d-flex" style="flex-direction: column; gap: 20px;">
        <div class="d-flex justify-content-end">
          <button 
            class="btn btn-primary" 
            data-bs-toggle="modal" 
            data-bs-target="#editScholarshipPopup"
            @click="setSelectedIndex(-1, 0)"
            :disabled="isFighterAssignLoading">
              Create Scholar
          </button>
        </div>
        <div v-if="totalTeam.length < 1" class="" style="width: 100%; background-color: #181822; border-radius: 5px; padding: 50px 10px;">
          <img src="~@/assets/no-data.png" alt="no data"
                style="display: block; margin-left: auto; margin-right: auto; width: 15%; min-width: 160px;">
        </div>
        <div v-else class="d-flex" style="flex-direction: column; gap: 20px;">
          <div v-for="(team, index) in totalTeam" :key="team.code" style="width: 100%; background-color:var(    --primary-background-color) ; border-radius: 8px; padding: 10px; display: flex; flex-direction: column; gap: 10px;">
            <div style="padding: 5px 10px;">
              <div class="detail-row">
                <div class="d-flex" style="gap: 15px;">
                  <div class="crop-container crop-container-profile" style="margin-right: 20px; margin-left: 20px;">
                    <img :src="require(`@/assets/icons/icon-scholar-${team.type ? 'private' : 'public'}.png`)" 
                      @load="onImageLoad" 
                      @error="onImageError" 
                      alt="profile">
                  </div>
                  <div class="detail-column" style="">
                    <div class="text-section" style="font-size: 20px; font-weight: 700 !important;">{{ team.name }}</div>
                    <div class="d-flex align-items-center">
                      <span class="text-section">Code :</span> {{ team.code }}
                      <div style="position: relative; display: inline-block;">
                        <button 
                        class="d-flex justify-content-center align-items-center" 
                        @click="copyText(team.code)" style="padding: 0 5px;">
                          <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" :fill="(isCopyClicked === `teamCode_${team.code}`)? 'grey' : 'white'">
                            <path d="M360-240q-29.7 0-50.85-21.15Q288-282.3 288-312v-480q0-29.7 21.15-50.85Q330.3-864 360-864h384q29.7 0 50.85 21.15Q816-821.7 816-792v480q0 29.7-21.15 50.85Q773.7-240 744-240H360Zm0-72h384v-480H360v480ZM216-96q-29.7 0-50.85-21.15Q144-138.3 144-168v-552h72v552h456v72H216Zm144-216v-480 480Z"/>
                          </svg>
                        </button>
                        <div v-if="isCopyClicked === `teamCode_${team.code}`" class="popup-message">
                          Copied!
                        </div>
                      </div>
                    </div>
                    <div>
                      Type:  <span class="text-section" :class="team.type ? 'text-private' : 'text-public'">
                        {{ team.type ? 'Private' : 'Public' }}
                      </span>
                    </div>
                    <div>
                      <span class="text-section">Total Fighters:</span> {{ team.fighters? team.fighters.length:0 }}
                      
                    </div>
                  </div>
                </div>
                <div class="detail-column" style="">
                  <div class="d-flex text-center" style="gap: 10px;">
                    <!-- <button class="btn btn-mini btn-custom d-flex justify-content-center align-items-center" style="font-size: 24px;">+</button> -->
                  </div>
                  <div class="detail-column" style="">
                    <div style="display: flex; align-items: center; gap: 5px;">
                      <span class="text-section">Energy :</span> 
                      {{ team.energy }}/100
                      <img :src="require('@/assets/icons/icon-energy.png')" alt="Energy Icon" style="height: 16px;">
                    </div>
                    <div>
                      <span class="text-section">Current Player :</span> {{ team.current_player }}
                    </div>
                    <div class="d-flex align-items-center">
                      <span class="text-section">Required Rank :</span> 
                      <img :src="require(`@/assets/icons/rank/${ team.required_rank.toUpperCase() }.png`)" alt="Energy Icon" style="height: 24px; margin: 0 3px;">
                      <span class="text-section">{{ team.required_rank }}</span>
                    </div>
                  </div>
                </div>
                <div class="detail-column" style="">
                  <div>
                    <span class="text-section">Share Rate :</span> {{ team.share_rate }}%
                  </div>
                  <div>
                    <span class="text-section">Win Rate :</span> {{ team.win_rate }}%
                  </div>
                  <div style="display: flex; align-items: center; gap: 5px;">
                    <span class="text-section">Total Jab Rewards:</span> 
                    {{ team.jab }}
                    <img :src="require('@/assets/currency/JAB.png')" alt="Matic Icon" style="height: 16px;">
                  </div>
                  <button class="btn btn-custom" disabled="true" style="margin-top: 10px;">Claim Rewards</button>
                </div>
                <div class="btn-edit">
                  <button 
                    class="btn btn-secondary d-flex justify-content-center align-items-center" 
                    style="" 
                    id="editBtn" 
                    data-bs-toggle="modal" 
                    data-bs-target="#editScholarshipPopup"
                    @click="setSelectedIndex(index, 0)"
                    :disabled="isFighterAssignLoading">
                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="white">
                      <path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z"/>
                    </svg>
                    <span class="text-section"> Edit </span>
                  </button>
                  <button 
                    class="btn btn-secondary d-flex justify-content-center align-items-center" 
                    style="" 
                    id="removeBtn" 
                    :data-bs-toggle="isFighterAssignLoading? null:'modal'" 
                    :data-bs-target="isFighterAssignLoading? null:'#confirmPopup'"
                    @click="setSelectedIndex(index, 1)"
                    :disabled="isFighterAssignLoading">
                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="white">
                      <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/>
                    </svg>
                    <span class="text-section"> Remove </span>
                  </button>
                  <button 
                    class="btn btn-secondary d-flex justify-content-center align-items-center" 
                    style="" 
                    id="historyBtn" 
                    :data-bs-toggle="isFighterAssignLoading? null:'modal'" 
                    :data-bs-target="isFighterAssignLoading? null:'#matchHistoryPopup'"
                    @click="setSelectedIndex(index)"
                    :disabled="isFighterAssignLoading">
                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="white">
                      <path d="M480-120q-138 0-240.5-91.5T122-440h82q14 104 92.5 172T480-200q117 0 198.5-81.5T760-480q0-117-81.5-198.5T480-760q-69 0-129 32t-101 88h110v80H120v-240h80v94q51-64 124.5-99T480-840q75 0 140.5 28.5t114 77q48.5 48.5 77 114T840-480q0 75-28.5 140.5t-77 114q-48.5 48.5-114 77T480-120Zm112-192L440-464v-216h80v184l128 128-56 56Z"/>
                    </svg>
                    <span class="text-section"> History </span>
                  </button>
                </div>
                <button 
                  :class="['collapse-trigger-toggle', { 'collapse-trigger-toggle-transform': !fightersCollapsed[index].isCollapsed }]" 
                  data-bs-toggle="collapse" 
                  :data-bs-target="'#fightersContent' + index"
                  aria-expanded="false" 
                  :aria-controls="'#fightersContent' + index"
                  @click="toggleCollapse(index)">
                  <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="#e8eaed" style="right: 0">
                    <path d="M480-360 280-559.33h400L480-360Z"/>
                  </svg>
                </button>
              </div>
            </div>
            <div class="collapse" :id="'#fightersContent' + index" :class="{ show: !fightersCollapsed[index].isCollapsed }" style="width: 100%; background-color: var( --dark-background-color); border-radius: 5px; padding: 15px;">
              <div v-if="fightersCollapsed[index].isLoading" class="fighter-content" style="justify-content: center; height: 60px;">
                <span><i class="fa fa-circle-o-notch fa-spin" /></span>
              </div>
              <div v-else class="fighter-content">
                <div style="width:80%">
                  <div class="d-flex fighter-list-container" style="">
                    <div v-for="(i, idx) in team.fighters" 
                      :key="i.id" 
                      class="crop-container-fighter" 
                      :data-bs-toggle="isFighterAssignLoading? null:'modal'" 
                      :data-bs-target="isFighterAssignLoading? null:'#confirmRemoveFighterPopup'"
                      @mouseover="isFighterAssignLoading? null:showHoverBox(`fighterImg_${team.id}_${idx}`)" 
                      @mouseleave="isFighterAssignLoading? null:hideHoverBox(`fighterImg_${team.id}_${idx}`)"
                      @click="isFighterAssignLoading? null:setSelectRemoveFighter(index, i)"
                      style="">
                      <img :id='`fighterImg_${team.id}_${i.id}`' :src="i.image?? ''" alt="fighter Image" @load="cropFighterImage(team.id, i.id, i.class_id)">
                      <div 
                        style="position: absolute; top: 0; font-size: 14px; text-shadow: 1px 1px 0px black, -1px -1px 0px black, 1px -1px 0px black, -1px 1px 0px black; font-weight: 500;">
                        #{{ i.id }}
                      </div>
                      <div v-if="hoveredIndex === `fighterImg_${team.id}_${idx}`" class="hover-box">
                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="white">
                          <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <button 
                  class="btn btn-secondary" 
                  data-bs-toggle="modal" 
                  data-bs-target="#assignFighterPopup"
                  @click="setSelectedIndex(index, 2)"
                  :disabled="isFighterAssignLoading"
                  >
                  Add Fighters
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="isLoading" class="loading-content" style="/*padding: 50px 0px;*/" >
      <div class="loading-bar"></div>
  </div>
  <EditScholarshipPopup 
    :title="selectedIndex === -1 ? 'Add Fighter\'s Pack' : ''" 
    :data="getScholarshipData()" 
    :rankList="rankList"
    @updateEditData="updateEditData"/>
  <AssignFighterPopup 
    :packData="getScholarshipData()"
    :totalFighters="totalFighters"
    @assignFighter="addFighters"/>
  <ConfirmPopup
    :title="getSelectRemoveDetail().title"
    :message="getSelectRemoveDetail().message"
    :confirmButtonText="'Confirm'"
    :cancelButtonText="'Cancel'"
    @confirm="onConfirm"/>
  <MatchHistoryPopup :scholar="getScholarshipData()"/>
  <ConfirmRemoveFighterPopup 
    :title="getSelectRemoveDetail().title"
    :message="getSelectRemoveDetail().message"
    :fighter="getSelectRemoveFighter()"
    @confirm="onConfirm"/>
</template>

<script>
import { useStore } from '@/stores/index.ts';
import axios from 'axios';
import { watch } from 'vue';
import { useToast } from 'vue-toast-notification';
import { ethers } from 'ethers';
import Web3 from 'web3';

import { getClassImageUrl, getElementImageUrl } from '@/utils/imageUtils.js';
import { shortenedWalletAddress } from '@/utils/walletUtils.js';

import EditScholarshipPopup from "@/components/popup/EditScholarshipPopup.vue";
import AssignFighterPopup from "@/components/popup/AssignFighterPopup.vue";
import ConfirmPopup from '@/components/popup/ConfirmPopup.vue';
import MatchHistoryPopup from '@/components/popup/MatchHistoryPopup.vue';
import ConfirmRemoveFighterPopup from '@/components/popup/ConfirmRemoveFighterPopup.vue';

import StakingABI from '@/contracts/StakingABI.json';
import FighterERC721ABI from '@/contracts/FighterERC721ABI.json';

export default {
  components: {
    EditScholarshipPopup,
    AssignFighterPopup,
    ConfirmPopup,
    MatchHistoryPopup,
    ConfirmRemoveFighterPopup
  }, 
  data() {
    return {
      isLoading: true,
      isFighterAssignLoading: false,
      isEditPopup: false,
      isAssignPopup: false,
      isRemoveTeam: false,
      isCopyClicked: "",
      fightersCollapsed: [],
      // fightersCollapsed: [{index: 0, isCollapsed: true}],
      totalTeam: [],
      // totalTeam: [{name: 'A', code: '0xAAA', current_player: '...', share_rate: 10, enrgy: 10, jab: 10, fighters: null}],
      selectedIndex: -2,
      selectedRemoveFighter: {},
      totalFighters: [],
      loadedTeams: 0,
      hoveredIndex: "",
      rankList: ['Bronze', 'Silver', 'Gold', 'Platinum']
    }
  },
  async mounted() {
    try {
        if(useStore().getWalletAddress!=''){
          this.fetchFighter().then(() => {
            this.fetchPack();
          });

          // await this.fetchFighter();
          // await this.fetchPack();
        }else{
          //alert('Please connect your wallet.');
          watch(
          () => useStore().getWalletAddress,
            async (newWalletAddress) => {
              if (newWalletAddress !== '') {
                this.fetchFighter().then(() => {
                  this.fetchPack();
                });
                // await this.fetchFighter();
                // await this.fetchPack();
              }
            }
          );
        }
    } catch (error){ 
        console.error('Error:', error);
    }
  },
  methods: {
    async fetchPack(){
        try {
            const accountAddress = useStore().getWalletAddress;
            const apiUrl = `https://reward-portal.kryptofighters.io/api/scholar/pack?wallet=${accountAddress}`;

            const response = await axios.get(apiUrl);
            console.log("gen1Response ",response);

            this.totalTeam = await Promise.all(response.data.data.map(async (pack, index) => {
            // this.totalTeam = response.data.data.map((pack, index)=>{
              const collapse = {index: index, isCollapsed: false, isLoading: false};
              this.fightersCollapsed.push(collapse);
              
              let fighterList = [];

              if (pack.fighters?.nft_fighter_token_ids?.length > 0) { 
                fighterList = await Promise.all(pack.fighters.nft_fighter_token_ids.map(async (fighterId) => {
                // fighterList = pack.fighters.nft_fighter_token_ids.map(fighterId => {
                  var getFighter = this.totalFighters.find(f => Number(f.id) == fighterId);
                  if(getFighter){
                    getFighter.isSelected = true;
                  }
                  else{
                    try{  
                      // const ownerApiUrl = `https://api.rarible.org/v0.1/items/KROMA:${process.env.VITE_FIGHTER_CONTRACT_ADDRESS}:${fighterId}`;
                      // const headers = {
                      //   'accept': 'application/json',
                      //   'X-API-KEY': process.env.VITE_RARIBLE_API_KEY,
                      // };
                      // const ownerResponse = await axios.get(ownerApiUrl, {headers});
                      // if(ownerResponse.data.ownerIfSingle.toLowerCase() === accountAddress.toLowerCase()){
                        const response = await axios.get(`https://s3.ap-southeast-1.amazonaws.com/metadata.kryptofighters.io/fighter/${fighterId}.json`);
                        console.log(response);
                        await this.pushFighterList(response);

                        getFighter = this.totalFighters.find(f => Number(f.id) == fighterId);
                        if(getFighter){
                          getFighter.isSelected = true;
                        }
                      // }
                    }
                    catch (error) {
                      console.error('Error fetching data from OpenSea:', error);
                    }
                  }
                  return getFighter || null; // Avoid pushing undefined if no match is found
                }));

                fighterList = fighterList.filter(fighter => fighter !== null); // Filter out null values
              }

              var rank = pack.required_rank?? 1;

              // if(rank < 3){
              //   rank = 3;
              // }

              return {
                id: pack._id,
                name: pack.scholar_name?? '', 
                code: pack.team_code?? 'xxxxxx', 
                type: Boolean(pack.team_type?? 0), // 0 public, 1 private
                current_player: (pack.current_player != null)? shortenedWalletAddress(pack.current_player.user_address):'-', 
                required_rank: this.rankList[rank-1],
                share_rate: pack.percent_share?? 0, 
                win_rate: pack.percent_win?? 0, 
                energy: pack.energy?? 0, 
                jab: pack.total_jab_rewards?? 0,
                fighters: fighterList
              };
            }));

            if(this.totalTeam.length === 0){
              this.isLoading = false;
            }
        } catch (error) {
            console.error('Error fetching data from OpenSea:', error);
        }
    },
    async pushFighterList(response){
      if(response){
        var imageUrl = response.data.image;
        imageUrl = imageUrl.replace("image/4x/", "card/no-bg/");
        const _tempData = {
            id: Number(response.data.properties.token_id),
            image: imageUrl,
            class_id: response.data.properties.class_id,
            element_id: response.data.properties.element,
            classImgUrl: getClassImageUrl(response.data.properties.class_id),
            elementUrl: getElementImageUrl(response.data.properties.element),
            isSelected: false,
            isSelecting: false
        }

        if(_tempData.id > 0){
          this.totalFighters.push(_tempData);
        }
      }
    },
    async fetchFighterData(fighters) {
      for (const fighter of fighters){
        try {
          const response = await axios.get(`https://s3.ap-southeast-1.amazonaws.com/metadata.kryptofighters.io/fighter/${fighter.tokenId}.json`);
          console.log(response);
          await this.pushFighterList(response);
        } catch (error) {
          console.error('Error fetching image:', error);
        }
      }
    },
    async fetchFighter(){
      try {
          const accountAddress = useStore().getWalletAddress;
          const collection = process.env.VITE_FIGHTER_CONTRACT_ADDRESS;
          const apiUrl = `https://api.rarible.org/v0.1/ownerships/search`;
          const requestBody = {
              size: 50,
              filter: {
                  blockchains: ['KROMA'],
                  owners: [`ETHEREUM:${accountAddress}`],
                  collections: [`KROMA:${collection}`]
              }
          };
          const response = await axios.post(apiUrl, requestBody, {
              headers: {
                  'accept': 'application/json',
                  'x-api-key': process.env.VITE_RARIBLE_API_KEY
              }
          });
          console.log("gen1Response ",response);

          await this.fetchFighterData(response.data.ownerships);
      } catch (error) {
          console.error('Error fetching data from OpenSea:', error);
      }
    },
    async addFighters(selectedFighters){
      this.fightersCollapsed[this.selectedIndex].isLoading = true;
      this.isFighterAssignLoading = true;

      // console.log('selectedFighters', this.selectedIndex);
      // console.log('selectedFighters', this.totalTeam[this.selectedIndex]);

      // selectedFighters = [
      //   ...this.totalTeam[this.selectedIndex].fighters,
      //   ...selectedFighters
      // ];

      // console.log('selectedFighters', selectedFighters);

      await this.updateFighters(selectedFighters[0]);
    },
    async updateFighters(selectedFighter, isRemove=false){
      if(selectedFighter === null){
        useToast().open({
            message: 'Update error : No this fighter.',
            position: 'top-right',
            type: 'error',
        });
        return;
      }

      try {
        const contractAddress = process.env.VITE_FIGHTER_STAKING_ADDRESS; 
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = await provider.getSigner();

        const contract = new ethers.Contract(contractAddress, StakingABI, signer);

        const nftContract = new ethers.Contract(
          process.env.VITE_FIGHTER_CONTRACT_ADDRESS,
          FighterERC721ABI, 
          signer 
        );

        console.log("tx selectedFighters ",selectedFighter);

        // const approvedAddress = await nftContract.getApproved(selectedFighter.id);
        // if (approvedAddress.toLowerCase() !== contractAddress.toLowerCase()) {
        //   console.log("Approving ...");
        //   const approveTx = await nftContract.approve(contractAddress, selectedFighter.id, {
        //       gasLimit: 60000, // Adjust as needed
        //       gasPrice: ethers.utils.parseUnits('0.003', 'gwei') // Adjust gas price
        //   });
        //   await approveTx.wait();
        // }

        var fightersUpdatedList = this.totalTeam[this.selectedIndex].fighters;
        if(isRemove){
          const tx1 = await contract.unstake(selectedFighter.id,
                    {
                        gasLimit: 6000000, // Gas limit
                        gasPrice: Web3.utils.toWei('0.003', 'gwei') // Gas price
                    });
          console.log("tx ",tx1);
          // fightersUpdatedList = this.totalTeam[this.selectedIndex].fighters;
        }
        else{
          const approvedAddress = await nftContract.getApproved(selectedFighter.id);
          if (approvedAddress.toLowerCase() !== contractAddress.toLowerCase()) {
            console.log("Approving ...");
            const approveTx = await nftContract.approve(contractAddress, selectedFighter.id, {
                gasLimit: 60000, // Adjust as needed
                gasPrice: ethers.utils.parseUnits('0.003', 'gwei') // Adjust gas price
            });
            await approveTx.wait();
          }

          const tx2 = await contract.stake(selectedFighter.id,
                    {
                        gasLimit: 6000000, // Gas limit
                        gasPrice: Web3.utils.toWei('0.003', 'gwei') // Gas price
                    });

          console.log("tx ",tx2);

          // fightersUpdatedList = [
          //   ...this.totalTeam[this.selectedIndex].fighters,
          //   ...selectedFighter
          // ];
          fightersUpdatedList.push(selectedFighter);
        }

        useToast().open({
            message: 'Update staking successfully.',
            position: 'top-right',
            type: 'success',
        });

        try {
          const apiUrl = `https://reward-portal.kryptofighters.io/api/scholar/pack/update_fighter`;
          
          const fighterList = fightersUpdatedList.map(fighter => {
              return fighter.id;
          });

          console.log('fighterList', fighterList);

          const requestBody = {
              team_id: this.totalTeam[this.selectedIndex].id,
              fighter_ids: fighterList
          };

          // console.log('requestBody',requestBody);
          const response = await axios.post(apiUrl, requestBody);
          console.log("gen1Response ",response);

          useToast().open({
              message: 'Update fighters successfully.',
              position: 'top-right',
              type: 'success',
          });

          this.assignFighter(fightersUpdatedList);

          this.fightersCollapsed[this.selectedIndex].isLoading = false;
          this.isFighterAssignLoading = false;
          this.selectedIndex = 0;
        } catch (error) {
            console.error('Error fetching data from OpenSea:', error);
            const errorMessage = error.message.length > 64 ? error.message.slice(0, 64) + '...' : error.message;
            useToast().open({
                message: 'Update error : ' + errorMessage,
                position: 'top-right',
                type: 'error',
            });
        }
      } catch (error) {
          console.error('Error fetching data from OpenSea:', error);
          const errorMessage = error.message.length > 64 ? error.message.slice(0, 64) + '...' : error.message;
          useToast().open({
              message: 'Update error : ' + errorMessage,
              position: 'top-right',
              type: 'error',
          });
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
          this.fightersCollapsed[this.selectedIndex].isLoading = false;
          this.isFighterAssignLoading = false;
          this.selectedIndex = 0;
      }
    },
    async onConfirm(){
      if(this.isRemoveTeam){
        await this.removePack();
      }
      else{
        await this.removeFighters();
      }
    },
    async removeFighters(){
      this.fightersCollapsed[this.selectedIndex].isLoading = true;
      this.isFighterAssignLoading = true;

      this.totalTeam[this.selectedIndex].fighters = this.totalTeam[this.selectedIndex].fighters.filter(f=>f.id !== (this.selectedRemoveFighter?.id?? 0));
      // console.log('removeFighters', this.totalTeam[this.selectedIndex]);
      this.totalTeam[this.selectedIndex].fighters.forEach(element => {
        element.isSelected = true;
      });
      // console.log('removeFighters', this.totalTeam[this.selectedIndex]);
      this.totalFighters.find(f=>f.id === (this.selectedRemoveFighter?.id?? 0)).isSelected = false;
      // console.log('removeFighters', this.totalFighters);
      // await this.updateFighters(this.totalTeam[this.selectedIndex].fighters);
      await this.updateFighters(this.selectedRemoveFighter, true);
    },
    async removePack(){
      const pack = this.getScholarshipData();
      if(pack){
        this.isLoading = true;
        try {
          const accountAddress = useStore().getWalletAddress;
          const apiUrl = `https://reward-portal.kryptofighters.io/api/scholar/pack/remove`;

          const requestBody = {
            wallet: accountAddress,
            team_code: pack.code
          };

          const response = await axios.post(apiUrl, requestBody);
          console.log("remove response ",response);

          // const index = this.totalTeam.findIndex(team => team.code === pack.code);
          // if (index !== -1) {
            this.totalTeam[this.selectedIndex].fighters.forEach(element => {
              this.totalFighters.find(f=>f.id === element.id).isSelected = false;
            });
            this.fightersCollapsed.splice(this.selectedIndex, 1);
            this.totalTeam.splice(this.selectedIndex, 1);
          // }
          console.log(this.totalTeam);
          this.selectedIndex = -1;
          this.isLoading = false;
          // if(this.totalTeam.length === 0){
          //   this.isLoading = false;
          // }
        } catch (error) {
            console.error('Error fetching data from OpenSea:', error);
        }
      }
      
    },
    toggleCollapse(index) {
      this.fightersCollapsed[index].isCollapsed = !this.fightersCollapsed[index].isCollapsed;
    },
    setSelectedIndex(index, popupIndex=-1){
      this.selectedIndex = index;

      console.log('setSelectedIndex', this.selectedIndex);
      switch(popupIndex){
        case 0: this.isEditPopup = true; break;
        case 1: this.isRemoveTeam = true; break;
        case 2: this.isAssignPopup = true; break;
      }
    },
    getScholarshipData(){
      console.log('this.selectedIndex '+this.selectedIndex);
      if(this.selectedIndex == -1){
        return {
                id: -1,
                name: '', 
                code: '', 
                type: false,
                current_player: '-',
                required_rank: this.rankList[0],
                energy: 100, 
                share_rate: 50, 
                win_rate: 50, 
                jab: 0, 
                fighters: null 
              };
      }
      else{
        console.log('this.selectedIndex ',this.totalTeam[this.selectedIndex]);
        return this.totalTeam[this.selectedIndex];
      }
    },
    updateEditData(newData){
      this.isEditPopup = false;
      if(this.selectedIndex == -1){
        this.totalTeam.push(newData);
        this.fightersCollapsed.push({index: this.totalTeam.length-1, isCollapsed: false, isLoading: false});
      }
      else{
        this.totalTeam[this.selectedIndex] = newData;
      }
    },
    assignFighter(selectedFighters){
      this.isAssignPopup = false;
      this.totalTeam[this.selectedIndex].fighters = selectedFighters;
      this.totalTeam[this.selectedIndex].fighters.forEach(element => {
        element.isSelected = true;
      });
      selectedFighters.forEach(element => {
        this.totalFighters.find(f=>f.id === element.id).isSelected = true;
      });
      console.log('this.totalFighters ', this.totalFighters);
      console.log('this.totalTeam[this.selectedIndex] ', this.totalTeam[this.selectedIndex]);
    },
    setSelectRemoveFighter(index, fighter){
      this.isRemoveTeam = false;
      this.selectedIndex = index;
      this.selectedRemoveFighter = fighter;
    },
    getSelectRemoveFighter(){
      return this.getScholarshipData()?.fighters?.find(f=>f.id === (this.selectedRemoveFighter?.id?? 0));
    },
    getSelectRemoveDetail(){
      var detail = {};
      if(this.isRemoveTeam){
        detail.title=`Remove scholarship team`;
        detail.message=`Please confirm your intention to remove this scholarship team name ${this.getScholarshipData()?.name?? ''}.`;
      }
      else{
        const selectedFighter = this.getSelectRemoveFighter();
        detail.title=`Remove fighter #${selectedFighter?.id?? 0}`;
        detail.message=`Please confirm your intention to remove this fighter tokenId #${selectedFighter?.id?? 0} from team.`;
      }
      return detail;
    },
    cropFighterImage(team_id, id, classId=0){
      this.$nextTick(() => {
        setTimeout(() => {
          const getElement = document.getElementById(`fighterImg_${team_id}_${id}`);
          var crop = {};
        
          console.log('classId'+classId);
          switch(classId){
            case 1: // ard
            case 4: crop = { top: '-30px', left: '-40px'}; break; // stk
            case 2: crop = { top: '-10px', left: '-35px'}; break; // bdg
            case 7: crop = { top: '-20px', left: '-45px'}; break; // dty
            default: crop = { top: '-35px', left: '-35px'}; break;
          } 
          
          if (getElement && crop) {
            console.log('crop',crop);
            getElement.style.top = crop.top;
            getElement.style.left = crop.left;
            getElement.style.height = crop.height;
          }
        }, 100);
      });
    },
    onImageLoad() {
        this.loadedTeams += 1;
        this.checkAllImagesLoaded();
    },
    onImageError() {
        this.loadedTeams += 1;
        this.checkAllImagesLoaded();
    },
    async checkAllImagesLoaded() {
        console.log(`loaded object: ${this.loadedTeams}/${this.totalTeam.length}`);
        if (this.loadedTeams === this.totalTeam.length) {
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.isLoading = false;
        }
    },
    showHoverBox(index) {
      this.hoveredIndex = index; 
    },
    hideHoverBox() {
      this.hoveredIndex = "";
    },
    copyText(code) {
      this.isCopyClicked = `teamCode_${code}`;
      console.log('copyText');
      const textToCopy = code;
      const textarea = document.createElement('textarea');
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);

      setTimeout(() => {
        this.isCopyClicked = "";
      }, 400);
    },
  }
};
                    
</script>

<style scoped>
.crop-container-profile {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.crop-container-fighter {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    background-color: var(--light-background-color);
    flex: 0 0 60px;
    overflow: hidden;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    margin: 0;
    position: relative;
}

.crop-container-fighter img {
    width: auto; 
    height: 300%;
    position: absolute;
}

.crop-container-fighter:hover .hover-box {
  visibility: visible; /* แสดงเมื่อ hover */
}

.crop-container {
    overflow: hidden;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    margin: 0;
    position: relative;
}

.crop-container img {
    width: auto; 
    height: 100%;
}

.hover-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; 
  visibility: visible;
  cursor: pointer;
}

.text-section {
  font-weight: 500;
}

.btn-mini {
  padding: 0;
  margin: 0;
  height: 30px;
  width: 30px;
}

.btn-custom {
  border: 1px solid white;
}

.text-center {
  align-items: center;
}

.detail-column {
  display: flex;
  flex-direction: column; 
  gap: 3px;
}

.detail-row {
  display: flex;
  justify-content: space-between;
}

.collapse-trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-right: 10px;
}

.collapse-trigger-toggle {
  transition: transform 0.3s;
}

.collapse-trigger-toggle.collapse-trigger-toggle-transform {
  transform: rotate(180deg);
}

.collapse.show {
  display: block;
}

.fighter-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-edit {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.fighter-list-container {
  gap: 20px; 
  flex-wrap: nowrap; 
  overflow-x: auto; 
  padding: 5px 0 5px 0;
}

.loading-bar {
    width: 100%;
    height: 2px;
    background-color: var(--primary-background-color);
    position: relative;
    overflow: hidden;
    border-radius: 0px; 
}

.loading-bar:before {
    content: "";   
    position: absolute;
    top: 0;
    left: -100%;             
    width: 100%;            
    height: 100%;           
    background-image: linear-gradient(100deg, #df4bd3 50%, #8322ce 100%);
    animation: loading 2s linear infinite;
}

@keyframes loading {
  0% {
    left: -100%;            /* Start off-screen to the left */
  }
  100% {
    left: 100%;             /* Move fully to the right */
  }
}

.loading-content {
    min-height: 650px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    position: relative;
    z-index: 1;
    /* top: -650px; */
    background-color: #100f17;
}

.popup-message {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    /* background-color: rgba(0, 0, 0, 0.7); */
    background: linear-gradient(135deg, #df4bd3, #a33af4);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    opacity: 0;
    animation: slideUp 0.5s ease-out forwards; 
  }

  @keyframes slideUp {
    0% {
      transform: translateX(-50%) translateY(10px);
      opacity: 0;
    }
    100% {
      transform: translateX(-50%) translateY(-15px); 
      opacity: 1;
    }
  }

@media (max-width: 768px) {
  .detail-row {
    flex-direction: column;
  }

  .fighter-content {
    flex-direction: column;
    gap: 15px;
  }

  .btn-edit {
    margin-top: 15px;
  }

  .btn-edit button{
    width: 100%;
  }

  .fighter-list-container {
    flex-wrap: wrap;
    overflow-x: unset;
    overflow-y: auto;
    height: 200px;
  }
}

.text-private {
  color: yellow; /* Customize color for Private */
}

.text-public {
  color: cyan; /* Customize color for Public */
}

</style>