<template>
  <div
    id="confirmRemoveFighterPopup"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="confirmRemoveFighterPopup"
    aria-hidden="true">
  >
    <div class="modal-dialog" style="--bs-modal-width: 550px;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="exampleModalLabel" class="modal-title">{{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="d-flex">
            <div class="" style="width: 40%;">
              <div style="position: relative; display: flex; justify-content: center;">
                <img :src="fighterData?.image?? ''" alt="fighter Image" style="width: 80%; z-index: 1;">
                <img :src="require(`@/assets/shadow.png`)" alt="Image" style="position: absolute; bottom: 7px; width: 120px; height: 13px;" >
              </div>
            </div> 
            <div class="d-flex justify-content-center" style="width: 60%; flex-direction: column; gap: 10px;">
              <h4 style="font-weight: 600; margin-bottom: 5px;">{{ fighterData?.name?? 'Fighter' }}</h4>
              <div class="detail-content" style="">
                <div class="detail-header" style="">
                  Rarity
                </div>
                <div sclass="detail-message" style="">
                  <div
                    class="detail-text"
                    style="color: lightgray;"
                  >
                    <span
                      :class="['text-rarity-' + fighterData?.rarity?? 'COMMON']"
                      style="font-weight: 600;"
                    >
                      {{ fighterData?.rarity?? 'COMMON' }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="detail-content" style="">
                <div class="detail-header" style="">
                  Class
                </div>
                <div class="detail-message" style="">
                  <img :src="fighterData?.classImgUrl?? ''" alt="Class Icon" class="icon-class" />
                  {{ fighterData?.className?? '' }}
                </div>
              </div>
              <div class="detail-content" style="">
                <div class="detail-header" style="">
                  Element
                </div>
                <div class="detail-message" style="">
                  <img :src="fighterData?.elementUrl?? ''" alt="Class Icon" class="icon-class" />
                  {{ fighterData?.element?? '' }}
                </div>
              </div>
              <div class="detail-content" style="">
                <div class="detail-header" style="">
                  Recommended Position
                </div>
                <div class="detail-message d-flex align-items-center" style="">
                  <img :src="getPosition()" alt="Class Icon" class="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="confirmAction"
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
  import axios from 'axios';
  import { getClassImageUrl, getElementImageUrl } from '@/utils/imageUtils.js';
  import { getClassPosition } from '@/stores/skill.ts';
  
  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      message: {
        type: String,
        required: true
      },
      fighter: {
        type: Object,
        required: true
      },
    },
    data() {
        return {
          // showModal: false
          fighterData: {}
        };
    },
    watch: {
      fighter: {
        handler(newVal) {
          console.log('fighter:', newVal);
          this.fetchFighterData(newVal?.id?? 0);
        },
        deep: true
      }
      // isVisible(newVal) {
      //   const modalElement = this.$refs.modal;
      //   const modalInstance = bootstrap.Modal.getOrCreateInstance(modalElement);

      //   if (newVal) {
      //     modalInstance.show(); // Show the modal
      //   } else {
      //     modalInstance.hide(); // Hide the modal
      //   }
      // },
    },
    methods: {
      closeModal() {
        // this.$emit('update:isVisible', false); // Close the modal
        console.log('closeModal');
      },
      confirmAction() {
        this.$emit("confirm"); // Trigger the confirm event
        this.closeModal(); // Close the modal
      },
      async fetchFighterData(tokenId) {
        try {
          const response = await axios.get(`https://s3.ap-southeast-1.amazonaws.com/metadata.kryptofighters.io/fighter/${tokenId}.json`);
          console.log(response);
          this.fighterData = {
              id: tokenId,
              name: response.data.name.replace("null", "Fighter"),
              image: response.data.image.replace("image/4x/", "card/no-bg/"),
              classId: response.data.properties.class_id,
              classImgUrl: getClassImageUrl(response.data.properties.class_id),
              className: response.data.properties.class_name.toUpperCase(),
              elementUrl: getElementImageUrl(response.data.properties.element),
              element: response.data.properties.element,
              rarity: response.data.properties.rarity.toUpperCase()

          }

        } catch (error) {
            console.error('Error fetching image:', error);
        }
      },
      getPosition(){
        return `https://info.kryptofighters.io/images/position/prefer-${getClassPosition(this.fighterData?.classId?? 0)}.png`;
      }
    }
  };
</script>
  
  <style scoped>
  .modal-overlay {
    display: none;
    /* other styles */
  }

  .modal.fade.show .modal-overlay {
    display: block;
  }

  .detail-text {
    color: white;
    display: inline-flex;
    align-items: center;
    background-color: var(--light-background-color);
    border-radius: 8px;
    justify-content: center;
    padding: 4px 8px;
    font-size: 14px;
    line-height: 20px;
  }

  .text-rarity-1, .text-rarity-COMMON {
    color: #B4B4B4; 
  }
  .text-rarity-2, .text-rarity-SUPERIOR {
      color: #29D403;
  }
  .text-rarity-3, .text-rarity-RARE {
      color: #06D8DB;
  }
  .text-rarity-4, .text-rarity-EPIC {
      color: #B069FF; 
  }
  .text-rarity-5, .text-rarity-LEGENDARY {
      color: #DBC22D; 
  }
  .detail-content {
    display: flex;
    gap: 15px;
  }

  .detail-header {
    width: 40%; 
    font-weight: 600;
  }

  .detail-message {
    width: 60%; 
  }
  </style>
  