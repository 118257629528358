<template>
  <div class="product-item">
      <div v-if="isLoading" style="margin-bottom: 50px;">
          <v-skeleton-loader
          :type="'card'"
          :animation="true"
          :width="200"
          :height="300"
          theme="dark"
          ></v-skeleton-loader>
      </div>
      <div v-else>
          <label class="card product-card-container" :class="{ active: option.isSelecting }" :id='`card_${index}`' style="width: 100%;">
              <input
                type="checkbox"
                class="btn-check"
                :class="{ active: option.isSelecting }"
                :checked="option.isSelecting"
                :value="option.id"
                @change="toggleChanged(option.id)"
                style=""
              />
              <div class="card-body">
                  <div class="product-header">
                      <div class="left-content product-title">
                          <div class="icon-container red-image">
                          <img :src="option.classImgUrl" alt="Product Icon" class="icon-class" />
                          </div>
                          <span :class="[ 'product-id']"># {{ option.id }}</span>
                      </div>
                      <img v-if="option.elementUrl !== ''" :src="option.elementUrl" alt="Product Icon" class="icon-element-right" :id='`element_${index}`' />
                  </div>
                  <div class="image-container">
                      <!-- <router-link :to="{ name: 'fighter', query: { id: product.id } }"> -->
                      <img :src="option.image" alt="Product Image" class="product-image" :id='`fighter_${index}`'/>
                      <img src="../assets/shadow.png" class="fighter-shadow" alt="Image" />
                  </div>
                  <div style="text-align: center; display: inline-flex; align-items: center; justify-content: center; color: gray; font-size: 14px;">
                    Recommended Position
                  </div>
                  <img :src="getPosition(option.class_id)" alt="Class Icon" class="" height="20"/>
                  <!-- <div class="" style="height: 20px; display: inline-flex; align-items: center;">
                    <span style="font-size: 14px;"> CD </span> 
                    <img v-if="option.timeLeft" :src="`../images/icon-cd.png`" class="icon-class">
                    <span> {{ option.timeLeft }}</span>
                  </div> -->
              </div>
          </label>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
import { getClassPosition } from '@/stores/skill.ts';

export default {
  props: {
      fighter: {
        type: Object,
        required: true
      },
      selectedCount: {
        type: Number,
        required: true
      },
      selectingFighterId: {
        type: Number,
        required: true
      }
  },
  data() {
      return {
          isLoading: true,
          option: { ...this.fighter },
          localSelectedCount: this.selectedCount
      }
  },
  watch: {
    fighter: {
      handler(newVal) {
        // console.log('Message prop updated1:', newVal);
        this.option = newVal;
        // console.log('Message prop updated1:', this.option);
      },
      deep: true
    },
    selectedCount: {
      handler(newVal) {
        // console.log('Message prop updated:', newVal);
        this.localSelectedCount = newVal;
        // console.log('Message prop updated:', this.localSelectedCount);
      },
      deep: true
    },
    selectingFighterId: {
      handler(newVal) {
        // console.log('Message prop updated:', newVal);
        if(this.option.id === newVal){
          this.option.isSelecting = true;
        }else{
          this.option.isSelecting = false;
        }
        // console.log('Message prop updated:', this.localSelectedCount);
      },
      deep: true
    }
  },
  mounted() {
    const image = new Image();

    image.onload = () => {
        this.isLoading = false;
    };
    image.src = this.fighter.image;
  },
  methods: {
      // setSelectedFighter() {
      //   console.log("masterItem "+this.option.id);
      //   this.$emit('refreshData', this.option.id);
      // },
      toggleChanged(id){
        if(this.localSelectedCount < 16){
          this.option.isSelecting = !this.option.isSelecting;
          console.log('toggleSelectedClass', this.option.isSelecting);
          // const _tempData = {
          //   id : this.option.id,
          //   class_id : this.option.class_id,
          //   imageUrl : this.option.image
          // }
          this.$emit('setSelectedFighter', this.option);
        }
      },
      getPosition(id){
        return `https://info.kryptofighters.io/images/position/prefer-${getClassPosition(id)}.png`;
      }
  }
};
</script>

<style scoped>

.product-item {
  text-align: center;
  margin-bottom: 0px;
}

.product-image {
  width: 160px;
  object-fit: cover;
  /* margin-bottom: 10px; */
}

.product-name {
  font-size: 16px;
  margin-top: 5px;
  text-align: left;
  display: inline-flex;
  /* Set display to inline-flex */
  align-items: center;
  /* Center items vertically */
  justify-content: space-between;
  width: 100%;
}


.product-usd-price {
  margin-top: 0px;
  font-size: 14px;
  color: var(--secondary-text-color);
}

.product-time {
  margin-top: 0px;
  padding-top: 0px;
  font-size: 14px;
  color: var(--secondary-text-color);
}



.product-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.with-class-icon {
  background-image: url('../assets/icon-stats-kroma.png');
  background-size: 20px 20px;
  /* Set the size of your icon */
  background-repeat: no-repeat;
  padding-left: 30px;
  /* Adjust the padding to make space for the icon */
}



.icon-element-right {
  width: 24px;
  height: 24px;
  margin-left: 10px;
  /* Adjust margin to create space between the two icons */
}

.left-content {
  display: flex;
  align-items: center;
}

.link {
  text-decoration: none;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Margin between product image and shadow */
.shadow-image {
  margin-top: 10px;
  /* Adjust margin as needed */
  width: 100px;
  height: 10px;
  object-fit: contain;
  /* Ensure the shadow image fits within its container */
}


.product-card-container:hover, .product-card-container.active {
  background: linear-gradient(135deg, #df4bd3, #a33af4);
  /* background: linear-gradient(180deg, rgba(13, 10, 22, 0.6), rgba(255, 255, 255, 0.13));  */
  cursor: pointer;
}

.product-card-container:disabled:hover {
  /* background: var(--secondary-background-color); */
  background: #2f2f2f4d;
  cursor: auto;
}

.fighter-shadow {
  position: relative; 
  top: -20px;
  width: 100px; 
  height: 10px;
}

.product-title{
  background-color: var(--soft-background-color);
  padding: 2px 6px;
  border-radius: 5px;
}

.text-element-ROCK {
  color: rgb(232, 163, 36);
}
.text-element-WATER {
  color: rgb(55, 118, 226);
}

.text-element-DARK {
  /* -webkit-text-stroke-width: 0.1px; 
  -webkit-text-stroke-color: white; */
  color: rgb(158, 84, 208)}
.text-element-CHAOS {
  color: rgb(211, 80, 211);
}
.text-element-FIRE {
  color: rgb(234, 102, 62);
}
.text-element-METAL {
  color: rgb(179, 187, 198);
}
.text-element-WIND {
  color: rgb(72, 197, 220);
}
.text-element-EARTH {
  color: rgb(73, 208, 73);
}
.text-element-LIGHT {
  color: rgb(235, 233, 233);
}

.red-image {
filter: hue-rotate(45deg); /* Change the hue to red (45 degrees) */
}

@media (max-width: 768px) {
  .product-image { 
      width: 120px;

      margin-bottom: 0;
      padding: 0 7px;
  }

  .card-body {
      padding: 0.5rem 0.5rem;
  }

  .product-header {
      margin: 0;
  }

  .icon-class {
      margin-right: 5px;
  }
  .icon-element-right {
      margin-left: 5px;
      width: 20px;
      height: 20px;
  }

  .fighter-shadow {
      top: -10px;
      width: 70px; 
      height: 10px;
  }

}
</style>